body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Define CSS variables */
:root {
  --header_color: #0A0A0A;
  --odds_blue: #72BBEF;
  --odds_pink: #FB95BA;
  --secondary: linear-gradient(90deg, #FF6F00, #FFB80C);
  --heading: #FFB80C;
  --link_blue: #167ac6;
  --sea_green: #30a9ad;
  --light_green: #90EE90;
  --very_large_font: 16px;
  --large_font: 15px;
  --medium_font: 14px;
  --small_font: 13px;
  --very_small_font: 11px;
}

.ag-theme-quartz .ag-header-cell-text {
  font-size: var(--large_font); /* Table headings */
}

.ag-theme-quartz .ag-cell {
  font-size: var(--large_font); /* Table rows */
}

@media(max-width:1000px){
  .ag-theme-quartz .ag-header-cell-text {
    font-size: var(--small_font); /* Table headings */
  }
  
  .ag-theme-quartz .ag-cell {
    font-size: var(--small_font); /* Table rows */
  }
}

