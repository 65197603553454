.changepass{
    padding-top:80px;
    background-color: white;
    height:350px;
    margin: 0px 10px ;
}
.helper{
    background-color: var(--secondary);
    color: var(--header_color);
    font-weight: 800;
    padding: 10px 10px;
    margin: 0px 10px ;
}
.table{
    width: 100%;
    display: flex;
    justify-content: center;
}
.label{
    display: inline-block;
    width: 200px;
    font-weight: bold;
}
.p{
  color: red;
  text-align: right;
}
.p::before{
  display: inline;
}
.materialUIInput {
  display: inline-block;
  /* box-sizing: border-box; */
  width: 50%;
  border-radius: 4px;
  background-color: white;
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 14px;
}
.button{
  display: flex;
  justify-content: center;
}
.input[type="submit"] {
  background: #1976d2;
  border-radius: 10%;
  color: white;
  border: none;
  padding: 10px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  width: 120px;
  border-radius: 4px;
  font-size: var(--large_font);
}

.container {
  font-size: var(--large_font);
}

@media(max-width: 700px){
  .container {
    margin-top: 10px;
    font-size: var(--small_font);
  }
  .input[type="submit"] {
    font-size: var(--small_font);
  }
}
  