.display{
    width: 100%;
    background-color: #fcf8f9;
    text-align: center;
    margin-bottom: 5px;
}
.topRowData{
    border-top:1px solid black;
}
.borderdata{
    padding: 3px;
    border-right:1px solid black;
    border-bottom: 1px solid black;
}
.borderdata:hover {
    background-color: rgb(224, 211, 211);
    box-shadow: 2px 2px 0 0 1px inset;
}
@media(min-width:1000px){
    .display{
        display: none;
    }
}
