/* padding:'5px',display:'flex',justifyContent:'space-around' */

.HelperLabel{
    padding: 5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.HelperText{
    font-size: var(--medium_font);
    font-weight: 700;
    border: 1px solid black;
    padding: 2px;
}

@media(max-width:700px) {
    .HelperText{
        font-size: var(--small_font);

    }
}
/* .help{
    min-width: 50px;
    flex-grow: 1;
} */