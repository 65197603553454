.input{
    width: 100%;
    height: 30px;
}
.label{
  margin-top: 20px;
  font-weight: 600;
}
.personinfo{
  margin-right: 50px;
  width:20%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}
.infohead{
  width: 98%;
  background-color: #e0f1f1;
  padding: 8px;
  font-weight: bold;
}
.flexinfo{
  display: flex;
  flex-wrap: wrap;
  max-width: 98%;
}

.container {
  padding:20px;
}

.p{
  color: red;
  text-align: right;
  font-size: 12px;
}