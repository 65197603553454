.cent{
    display:flex;
    justify-content:center;
  }
  .rotate {
    animation: rotation 1s infinite linear;
  }
  
  @keyframes rotation {
    from {
      transform: rotateY(0deg);
    }
    to {
      transform: rotateY(359deg);
    }
  }  