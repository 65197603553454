.dBlock {
    display: block;
    position: relative;
}
.disabled {
    pointer-events: none;
    opacity: 0.6;
}
.loadingOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loadingGif {
    width: 50px;
}

.dNone {
    display: none;
}

.Fancy {
    background-color: white;
    margin-top: 20px;
}

.tableName {
    font-weight: 700;
    font-size: var(--large-font);
}

.headGrid {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--header_color);
    color: var(--heading);
    padding: 5px;
    margin-bottom: 5px;
}

.upperGrid {
    display: grid;
    grid-template-columns: 60% 40%;
    width: 100%;
    text-align: center;
    border-bottom: 0.5px solid rgb(175, 169, 169);
}

.yes {
    background-color: white;
    padding: 4px 0;
    font-weight: 600;
}

.no {
    background-color: white;
    padding: 4px 0;
    font-weight: 600;
}

.midGrid {
    display: grid;
    grid-template-columns: 60% 40%;
    width: 100%;
    text-align: center;
    border-bottom: 0.5px solid rgb(175, 169, 169);
}

.textLeft {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 10px;
}

.textLeft div:first-child {
    font-size: var(--medium_font);
    font-weight: 600;
    color: rgb(71, 50, 50);
}

.bookBtn {
    background-color: var(--sea_green); /* Blue background */
    border: none;
    color: white; /* White text */
    padding: 8px 15px; /* Padding */
    text-align: center; /* Centered text */
    text-decoration: none; /* No underline */
    display: inline-block; /* Inline-block */
    font-size: var(--medium_font); /* Font size */
    margin: 4px 2px; /* Margin */
    cursor: pointer; /* Pointer cursor on hover */
    border-radius: 4px; /* Rounded corners */
    transition: background-color 0.3s ease; /* Smooth transition */
}

.bookBtn:hover {
    background-color: #27898b;
}

.textCenter {
    text-align: center;
    font-size: var(--very_large_font);
}

@media(max-width:700px) {
    .textLeft div:first-child {
        font-size: var(--small_font);
    }
    .textLeft {
        margin: 5px;
    }
    .tableName {
        font-size: var(--small_font);
    }
    .textCenter{
        font-size: var(--medium_font);
    }
    .bookBtn {
        font-size: var(--small_font);
    }
}

.odds {
    display: grid;
    grid-template-columns: 25% 25% 50%;
}

.mobile {
    display: flex;
    align-items: center;
    justify-content: center;
}

.back {
    background-color: var(--odds_blue);
    cursor: pointer;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: var(--very_large_font);
}

.lay {
    background-color: var(--odds_pink);
    cursor: pointer;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: var(--very_large_font);
}
@media(max-width:700px) {
    .back{
        font-size: var(--medium_font);
    }
    .lay {
        font-size: var(--medium_font);
    }

}

.deactivate {
    display: flex;
    align-items: center;
    background-color: #f2f2f2;
    color: red;
    font-size: 14px;
}

.lowerBack {
    background-color: #d7e8f4;
}

.lowerLay {
    background-color: #f6e6ea;
}

.tiny {
    font-size: var(--small_font);
    cursor: pointer;
}

@media(max-width:700px) {
    .tiny {
        font-size: var(--very_small_font);
    }
}

.lowerGrid {
    padding-top: 5px;
    margin-bottom: 5px;
    display: grid;
    grid-template-columns: auto auto auto auto;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    font-size: 0.9em;
}

.lowerGrid div input {
    font-size: 0.9em;
    margin: 0 3px;
    padding: 2px;
    padding-left: 6px;
    width: 70px;
    height: 33px;
}

.stake {
    border: 0;
    padding: 0.6em 1em;
    height: 30px;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 5px;
    width: 5em;
    margin-right: 5px;
}

.cancel {
    background-color: rgba(255, 250, 250, 0.925);
    color: #1E1E1E;
    cursor: pointer;
    font-size: 0.85rem;
    font-weight: 700;
    padding: 0.6em 2.5em;
    border: 0;
    border-radius: 5px;
    margin-right: 5px;
}

.cancel:hover {
    background-color: white;
    box-shadow: 2px 2px 0 0 1px inset;
}

.inactiveBet {
    border-radius: 5px;
    font-size: 0.85rem;
    font-weight: 700;
    padding: 0.6em 2.5em;
    border: 0;
    background-color: #A4A4A4;
    color: white;
    cursor: not-allowed;
    margin: 0 5px;
}

.activeBet {
    border-radius: 5px;
    font-size: 0.85rem;
    font-weight: 700;
    padding: 0.6em 2.5em;
    border: 0;
    background-color: #121212;
    color: #ff9900;
    cursor: pointer;
    margin-right: 5px;
}

.activeBet:hover {
    background-color: #1b1a1a;
}

.weight {
    display: flex;
    flex-direction: column;
    background-color: white;
    height: 33px;
    width: 70px; 
    padding: 3px 0;
    margin: 0 3px;
    border-radius: 5px;
    border: 1px solid black;
}

.weight span:first-child {
    font-size: 0.9em;
}

.weight span:last-child {
    font-size: 10px;
}

.editableWeight {
    border-radius: 5px;
    width: 50px;
}

@media(max-width:1000px) {
    .stake {
        border-radius: 0;
    }
    .cancel {
        width: 10em;
    }
    .activeBet {
        width: 11em;
    }
    .inactiveBet {
        width: 11em;
    }
    .inc,
    .dec {
        height: 30px;
        width: 30px;
        border: 0;
        font-size: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #1a8ee1;
    }
    .lowerGrid {
        display: grid;
        grid-template-columns: 50% 50%;
    }
    .lowerGrid>div:nth-child(1) {
        order: 3;
    }
    .lowerGrid>div:nth-child(4) {
        order: 3;
    }
    .lowerGrid>div:nth-child(2) {
        max-width: 90%;
        margin: 0 auto;
    }
    .lowerGrid>div:nth-child(3) {
        max-width: 90%;
        margin: 0 auto;
    }
    .lowerGrid div input{
        height: 25px;
    }
    .weight {
        height: 30px;
        margin-bottom: 5px;
    }
}

@media(min-width:1000px) {
    .inc,
    .dec {
        display: none;
    }
}

.bottomGrid {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto;
    width: 100%;
    font-size: 0.9em;
    padding-bottom:10px;
}

.bottomGrid button{
    background-color: rgba(255, 250, 250, 0.925);
    color: #1E1E1E;
    cursor: pointer;
    font-size: 0.85rem;
    padding: 0.6em 2.5em;
    border: 0;
    border-radius: 5px;
}

.activeBack {
    background-color: #1a8ee1;
    color: white;
}

.activeLay {
    background-color: rgb(240, 112, 133);
    color: white;
}

@media(max-width:1000px) {
    .bottomGrid button {
        margin: 10px 0;
        padding: 0.6em 1.1em;
    }
    .odds {
        grid-template-columns: 50% 50%;
    }
    .mobile {
        display: none;
    }
}